let isSuperAdmin = false
const userData = JSON.parse(localStorage.getItem('userData'))
if (userData.is_super_admin.toString() === '1') {
  isSuperAdmin = true
}

export default [
  {
    header: 'Pages',
  },
  {
    title: 'Kiosk',
    route: 'admin-kiosks',
    icon: 'GridIcon',
  },
  {
    title: 'Settings',
    route: 'admin-settings',
    icon: 'SettingsIcon',
  },
  isSuperAdmin ? {
    title: 'Users',
    route: 'admin-users',
    icon: 'UserIcon',
  } : '',
  {
    title: 'Change Password',
    route: 'change-password',
    icon: 'KeyIcon',
  },

]
